//to identify page is an article irrespective of language
window._isAnArticle = window.location.href.toLowerCase().match(/(viewarticle|artikelansicht|voirarticle|verarticulo|verartigo)/) ? true : false;


const stopWords = {
    de: new Set([
        "der",
        "die",
        "das",
        "ein",
        "eine",
        "eines",
        "einem",
        "einer",
        "derjenige",
        "diejenige",
        "dasjenige",
        "deren",
        "dessen",
        "und",
        "oder",
        "aber",
        "denn",
        "beziehungsweise",
        "sowohl als auch",
        "entweder oder",
        "weder noch",
        "zweiteg",
        "jedoch",
        "nämlich",
        "da",
        "wenn",
        "ob",
        "weil",
        "denn",
        "dass",
        "sodass",
        "damit",
        "als",
        "sobald",
        "solange",
        "seitdem",
        "sofern",
        "in",
        "auf",
        "neben",
        "über",
        "unter",
        "vor",
        "hinter",
        "zwischen",
        "bei",
        "durch",
        "für",
        "gegen",
        "um",
        "an",
        "aus",
        "ohne",
        "vorm",
        "vorbei",
        "seit",
        "trotz",
        "während",
        "gegenüber",
        "gemäß"
    ]),
    it: new Set([
        "il",
        "la",
        "i",
        "le",
        "un",
        "una",
        "uno",
        "degli",
        "delle",
        "dei",
        "dello",
        "della",
        "dell'",
        "quello",
        "quella",
        "quelli",
        "quelle",
        "quel",
        "quell'",
        "quella",
        "quello",
        "e",
        "o",
        "ma",
        "quindi",
        "perciò",
        "dunque",
        "oppure",
        "né",
        "né...né",
        "se",
        "perché",
        "siccome",
        "poiché",
        "in quanto",
        "quando",
        "ossia",
        "mentre",
        "anche se",
        "affinché",
        "finché",
        "sebbene",
        "visto che",
        "giacché",
        "a meno che",
        "cioè",
        "pertanto"
    ]),
    es: new Set([[
        "el",
        "la",
        "los",
        "las",
        "un",
        "una",
        "unos",
        "unas",
        "lo",
        "el cual",
        "la cual",
        "los cuales",
        "las cuales",
        "lo que",
        "lo cual",
        "los que",
        "las que",
        "quien",
        "quienes",
        "aquel",
        "aquella",
        "aquellos",
        "aquellas",
        "y",
        "e",
        "o",
        "u",
        "ni",
        "que",
        "pero",
        "sino",
        "aunque",
        "si",
        "porque",
        "como",
        "cuando",
        "mientras",
        "donde",
        "en cuanto",
        "así",
        "entonces",
        "luego",
        "más",
        "menos",
        "como si",
        "siempre que",
        "para que",
        "aunque",
        "a pesar de que",
        "puesto que",
        "por tanto",
        "desde que",
        "ya que",
        "según",
        "tal como",
        "no sólo",
        "sino también",
        "tanto como",
        "a",
        "ante",
        "bajo",
        "cabe",
        "con",
        "contra",
        "de",
        "desde",
        "durante",
        "en",
        "entre",
        "hacia",
        "hasta",
        "mediante",
        "para",
        "por",
        "según",
        "sin",
        "so",
        "sobre",
        "tras",
        "vía",
        "hasta",
        "via"
    ]]),
    fr: new Set([
        "le",
        "la",
        "les",
        "un",
        "une",
        "des",
        "du",
        "de la",
        "de l'",
        "au",
        "aux",
        "des",
        "celui",
        "celle",
        "ceux",
        "celles",
        "celui-ci",
        "celle-ci",
        "ceux-ci",
        "celles-ci",
        "et",
        "ou",
        "mais",
        "donc",
        "or",
        "ni",
        "car",
        "que",
        "si",
        "comme",
        "lorsque",
        "puisque",
        "tandis que",
        "parce que",
        "ensuite",
        "cependant",
        "alors que",
        "comme si",
        "si bien que",
        "en effet",
        "quoique",
        "puis",
        "à",
        "de",
        "en",
        "dans",
        "sur",
        "sous",
        "devant",
        "derrière",
        "par",
        "avec",
        "pour",
        "sans",
        "vers",
        "entre",
        "chez",
        "à côté de",
        "en face de",
        "à travers",
        "quant à",
        "prés de",
        "loin de",
        "via"
    ]),
    en: new Set(["a",
        "an",
        "the",
        "and",
        "for",
        "but",
        "so",
        "or",
        "yet",
        "nor",
        "although",
        "before",
        "after",
        "because",
        "if",
        "how",
        "once",
        "so",
        "since",
        "that",
        "unless",
        "until",
        "when",
        "either",
        "not",
        "only",
        "neither",
        "whether",
        "aboard",
        "about",
        "above",
        "across",
        "after",
        "against",
        "along",
        "amid",
        "among",
        "around",
        "as",
        "at",
        "before",
        "behind",
        "below",
        "beneath",
        "beside",
        "besides",
        "between",
        "beyond",
        "but",
        "by",
        "despite",
        "down",
        "during",
        "except",
        "for",
        "from",
        "in",
        "inside",
        "into",
        "like",
        "near",
        "of",
        "off",
        "on",
        "onto",
        "outside",
        "over",
        "past",
        "per",
        "plus",
        "save",
        "since",
        "than",
        "through",
        "to",
        "toward",
        "towards",
        "under",
        "underneath",
        "unlike",
        "until",
        "up",
        "upon",
        "versus",
        "via",
        "with",
        "within",
        "without"])
};


export {stopWords as default};