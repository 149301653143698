function ibadsStaticTargetKeys(k, v) {
  //tug key default case
  webmd.ads2.setPageTarget("tug", "0");
  // geoc key
  if (PageMetadata.reqHeaders) {
    let geoc = PageMetadata.reqHeaders.geoc || "0";
    webmd.ads2.setPageTarget("geoc", geoc);
  }
  // Checking Auth level values and assign Auth key value.
  var authLevel =
    window &&
    window.PageMetadata &&
    window.PageMetadata.authVar &&
    window.PageMetadata.authVar.authLevel;
  if (authLevel === "0" || authLevel === "1" || authLevel === "2") {
    webmd.ads2.setPageTarget("auth", authLevel);
  }
}

export { ibadsStaticTargetKeys as default };
