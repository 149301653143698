import settings from '../ib-ads-lib/settings.js';

function SetProfSizes(){

    webmd.ads2.defaultSizes = {
        '101': [
            [728, 90],
            [970, 250],
            [970, 90],
            [970,200],
            'fluid'
        ],
        '104': [
            [728, 90],
            [970, 90],
            [970, 250],
            'fluid'
        ],
        '105': [
            [1, 1],
            [1, 2],
            'fluid'
        ],
        '106': [
            [1, 2],
            [1, 1],
            'fluid'
        ],
        '107': [
            [2, 14],
            'fluid'
        ],
        '113': [
            [160, 600],
            'fluid'
        ],
        '122': [
            [160, 600],
            [160, 601],
            [300, 170],
            [300, 171],
            [300, 250],
            [300, 251],
            [300, 350],
            [300, 351],
            [300, 600],
            [300, 601],
            [300, 350],
            [300, 800],
            [300, 801],
            'fluid'
        ],
        '124': [
            [300, 250]
        ],
        '126': [
            [300, 250]
        ],
        '141': [
            [728, 90],
            [728, 91],
            [970, 90]
            [970, 91],
            'fluid'
        ],
        '142': [
            [2, 4],
            [320, 50],
            [300, 50],
            [300, 400],
            [300, 250],
            'fluid'
        ],
        '143': [
            [300, 50],
            [300, 51],
            [300, 170],
            [300, 171],
            [300, 190],
            [300, 191],
            [300, 250],
            [300, 225],
            [300, 226],
            [300, 251],
            [300, 600],
            [320, 50],
            [320, 51],
            'fluid'
        ],
        '144' : [
            [320, 50],
            [320, 51],
            [300, 50],
            [300, 51],
            [300, 170],
            [300, 171],
            [300, 190],
            [300, 191],
            [300, 225],
            [300, 226],
            [300, 250],
            [300, 251],
            [300, 400],
            'fluid'
        ],
        '145': [
            [2,7],
            [320, 50],
            [300, 50],
            [300, 250],
            [300, 400],
            [728, 100],
            [728, 92],
            'fluid'
        ],
        '146': [
            [320, 50],
            [320, 51],
            [300, 50],
            [300, 51],
            [300, 170],
            [300, 171],
            [300, 190],
            [300, 191],
            [300, 225],
            [300, 226],
            [300, 250],
            [300, 251],
            'fluid'
        ],
        '148': [
            [320, 50],
            [320, 51],
            [300, 50],
            [300, 51],
            [300, 170],
            [300, 171],
            [300, 190],
            [300, 191],
            [300, 225],
            [300, 226],
            [300, 250],
            [300, 251],
            "fluid"
        ],
        '201': [
            [2, 1],
            'fluid'
        ],
        '202': [
            [2, 2],
            'fluid',
        ],
        '203': [
            [2, 2],
            'fluid'
        ],
        '319': [
            [10, 10]
        ],
        '420': [
            [2, 3],
            [300, 255],
            'fluid'
        ],
        '421': [
            [1, 4],
            [1, 12],
            'fluid'
        ],
        '520': [
            [2, 3],
            [400, 340],
            [400, 500],
            [300, 255],
            'fluid'
        ],
        '521': [
            [2, 11],
            'fluid'
        ],
        '615': [
            [10, 10]
        ],
        '622': [
            [2, 9],
            'fluid'
        ],
        '632': [
            [2, 9],
            'fluid'
        ],
        '633': [
            [2, 9],
            'fluid'
        ],
        '712': [
            [728, 90],
            [970, 90],
            'fluid'
        ],
        '722': [
            [2, 9],
            'fluid'
        ],
        '800': [
            [2, 10]
        ],
        '820': [
            [4, 1]
        ],
        '904': [
            [2, 18],
            'fluid',
        ],
        '905': [
            [300, 250],
            [280, 280],
            [310, 174],
            'fluid'
        ],
        '906': [
            [2, 18],
            'fluid',
        ],
        '907': [
            [2, 18],
            'fluid'
        ],
        '910': [
            [1, 2],
            [160, 600],
            [160, 601],
            [300, 170],
            [300, 171],
            [300, 250],
            [300, 251],
            [300, 600],
            [300, 601],
            [300, 800],
            [300, 801],
            [300, 350],
            [300, 351],
            'fluid'
        ],
        '911': [
            [160, 600],
            [160, 601],
            [300, 600],
            [300, 601],
            [300, 800],
            [300, 801],
            [300, 250],
            [300, 251],
            [300, 350],
            [300, 351],
            [300, 170],
            [300, 171],
            'fluid'
        ],
        '912': [
            [2, 8],
            [300, 250],
            [300, 251],
            [300, 350],
            'fluid'
        ],
        '913': [
            [160, 600],
            [160, 601],
            [300, 600],
            [300, 601],
            [300, 800],
            [300, 801],
            [300, 250],
            [300, 251],
            [300, 350],
            [300, 351],
            [300, 170],
            [300, 171],
            [300, 348],
            [300, 516],
            'fluid'
        ],
        '914': [
            [300, 600],
            [300, 601],
            [300, 800],
            [300, 801],
            [160, 600],
            [160, 601],
            [300, 250],
            [300, 251],
            [300, 350],
            [300, 351],
            [300, 170],
            [300, 171],
            [300, 292],
            [300, 364],
            'fluid'
        ],
        '916': [
            [2, 18],
            'fluid'
        ],
        '917': [
            [2, 18],
            'fluid'
        ],
        '921': [
            [300, 100],
            'fluid'
        ],
        '940': [
            'fluid',
            [2, 16]
        ],
        '941': [
            'fluid',
            [2, 16]
        ],
        '942': [
            [220, 120],
            'fluid'
        ],
        '943': [
            [468, 120],
            'fluid'
        ],
        '944': [
            [130, 180],
            'fluid'
        ],
        '945': [
            [445, 445],
            'fluid'
        ],
        '970': [
            [1, 12],
            'fluid'
        ],
        '971': [
            [1, 13],
            'fluid'
        ],
        '1004': [
            [300, 50],
            [320, 50],
            'fluid'
        ],
        '1005': [
            [1, 2],
            'fluid'
        ],
        '1007': [
            [2, 8]
        ],
        '1122': [
            [300, 50],
            [300, 51],
            [300, 170],
            [300, 190],
            [300, 250],
            [300, 225],
            [300, 292],
            [300, 364],
            [300, 400],
            [300, 800]
            [320, 50],
            [320, 51],
            'fluid'
        ],
        '1145': [
            [320, 80],
            [375, 80],
            [320, 52],
            [300, 52],
            [2, 7],
            'fluid'
        ],
        '1420': [
            [1, 12],
            [2, 3],
            [300, 250],
            [300, 254],
            'fluid'
        ],
        '1421': [
            [1, 12],
            'fluid'
        ],
        '1520': [
            [2, 3],
            [1, 12],
            [300, 254],
            [300, 250],
            [300, 340],
            [375, 340],
            [300, 500],
            [375, 500],
            'fluid'
        ],
        '1521': [
            [2, 11],
            'fluid'
        ],
        '1522': [
            [300, 250],
            'fluid'
        ],
        '1523': [
            [2, 15],
            'fluid'
        ],
        '1622': [
            [2, 9],
            'fluid'
        ],
        '1632': [
            [2, 9],
            'fluid'
        ],
        '1633': [
            [2, 9],
            'fluid'
        ],
        '1703': [
            [300, 250],
            'fluid'
        ],
        '1712': [
            [300, 250],
            'fluid'
        ],
        '1722': [
            [2, 9],
            'fluid'
        ],
        '1800': [
            [2, 10]
        ],
        '1909': [
            'fluid',
            [2, 5]
        ],
        '1970': [
            [1, 12],
            'fluid'
        ],
        '1971': [
            [1, 13],
            'fluid'
        ],
        '2017': [
            [1, 4],
            'fluid'
        ],
        '2122': [
            [300, 250]
        ],
        '2420': [
            [2, 3]
        ],
        '2520': [
            [1, 5]
        ],
        '5000': [
            [650, 90],
            [650, 250],
            [925, 250],
            [728, 90],
            [728, 91],
            'fluid'
        ]
    };

    // TO-DO: Work with framesdata to fix on affiliate side
    if(location.hostname.match('framesdata') != null) webmd.ads2.defaultSizes['113'] = [[150, 450],'fluid'];

    webmd.ads2.sizes = Object.keys(settings.adSize).length === 0 ? Object.assign({}, webmd.ads2.defaultSizes) : settings.adSize;

    return webmd.ads2.sizes;
}
export {SetProfSizes as default};
