import utils from '../common/utility.js';

export default class Webmd {
    constructor() {
        this.slotCount = 0;
        this.ads = {};
        this.pageTargets = {};
        this.pageExclusions = {};
        this.sizes = {};
        this.setAdTarget();
        /**
         * used to store the state of display function
         */
        this.displayCalled = false;
        this.displayCalledCount = 0;
        // Set up array of functions to run after the Google code loads.
        // google.cmd is the array of functions to run.
        // You must use google.cmd.push() to add functions (don't do this directly, use webmd.ads.googleDefer instead).
        // After the Google code loads, it replaces the push() function so instead of adding to the array,
        // it runs the function immediately.
        window.googletag = window.googletag || {};
        googletag.cmd = googletag.cmd || [];
    }

    setAdTarget() {
        this.adTarget = window.adTarget || ['consumer', 'webmd'];
        if (window.location.hostname.indexOf('univadis') !== -1) {
            window.adTarget.unshift('profnetwork');
        }
    }
    
    /**
     * Google network code that is sent with each ad.
     * This, along with the adTarget parameter, is used when defining the ad slots.
     */
    networkCode() {
        let networkId = (typeof adNetworkId !== 'undefined') ? adNetworkId : '4312434'; //default live network id
        if (window.location.href.indexOf(".proddev.") > -1)
            networkId = '8668145'; //sandbox network id
        return networkId;
    }

    /**
     * Function to call once to initialize the ads framework.
     * Can be done at the top or bottom of the page.
     * Calling this multiple times will have no ill effects.
     */
    init() {
        // Do a check to make sure nobody tries to run init twice,
        // because that might really screw things up.
        if (this.init.flag) {
            return;
        }
        this.init.flag = true;
        this.frameWork = window.ibAds || window.mdscapeAds;
        this.frameWork.proclivityData = {}

        // Load the javascript library from google
        this.googleLoad();

        // Allow this function to be chained
        return this;
    }

    /**
     * Function to tell if init() has already been called.
     * You can use this to determine if DFP ads are defined on the page.
     * NOTE: this must be called after webmd.ads2.init() has been called,
     * otherwise it will report that DFP ads are not on the page.
     * @returns Boolean
     */
    isInitialized() {
        return Boolean(this.init.flag);
    }

    /**
     * Add a function to the google asynchronous command queue.
     *
     * @param Function f Function to run after google code has loaded.
     * @param Number i Index for the new function to be pushed into.
     */
    googleDefer(f) {
        // Push the function onto the command array so google can run it later.
        //
        // NOTE: After the google code loads, it actually overrides the push() function
        // so instead of adding to the array, it runs the function immediately.
        // Because of this you should only modify the array using the push() function,
        // you should not attempt to add functions to the queue any other way.
        googletag.cmd.push(f);

        // Allow this function to be chained
        return this;
    }

    /**
     * Call this once to load the google code.
     * For best results call this near the top of the page.
     * This function is called automatically by init()
     */
    googleLoad() {
        let gads, node, useSSL;

        // Asynchronously load the google code
        gads = document.createElement("script");
        gads.async = true;
        gads.type = "text/javascript";
        useSSL = "https:" === document.location.protocol;
        gads.src = (useSSL ? "https:" : "http:") + "//securepubads.g.doubleclick.net/tag/js/gpt.js";
        node = document.getElementsByTagName("script")[0];
        node.parentNode.insertBefore(gads, node);

        // Allow this function to be chained
        return this;
    }

    /**
     * Function to check if a variable is a plain object
     * const arr = ['a'];
     * const obj = {'a': 1};
     * isPlainObject(arr) // returns false
     * isPlainObject(obj) // return true
     *
     * @param obj
     * @returns {boolean}
     */
    isPlainObject(obj) {
        return Object.prototype.toString.call(obj) === '[object Object]';
    }

    /**
     * Set a page-level key/value target that will be used for all ads.
     * This function can set a single key/value, or multiple key/value pairs.
     * The value can be a single value, or an array of values.
     *
     * @param String|Object key The name of the key to add, or an object containing multiple key/value pairs.
     *
     * @param String|Array [value] If the "key" parameter is a string, then this is the value for the single key to set.
     * If the "key" parameter is an object, this parameter is not used.
     * The value can be a string, or an array of values.
     * The value cannot be a number, however it can be an array of numbers (possible bug in google code?)
     *
     * @example
     * // Set a single key/value
     * webmd.ads2.setPageTarget('xpg', '1234');
     *
     * @example
     * // Set a single key with multiple values
     * webmd.ads2.setPageTarget('xpg', ['1234', '5678']);
     *
     * @example
     * // Set multiple key/value pairs all at once
     * webmd.ads2.setPageTarget({xpg:'1234', gender:'male', m:[1,2,5]});
     */
    setPageTarget(key, value) {

        const self = this;

        // We can't call the google code until it is loaded,
        // so we'll add a command to the queue to run later
        self.googleDefer(function () {

            // Check if "key" is an object
            if (self.isPlainObject(key)) {

                // Loop through multiple key/value pairs
                // keysArr is an array of the keys of the 'key' object
                const keysArr = Object.keys(key);
                let v;
                keysArr.forEach((k) => {
                    v = key[k];
                    v = String(self.fixTarget(k, v));
                    // Add the key and value
                    // Note: for some reason the DFP code does not allow
                    // numeric values, so cast the value to a String
                    googletag.pubads().setTargeting(k, v);
                    // Save the key and value for later reference
                    self.pageTargets[k] = v;
                });

            } else { // Add a single key and value

                value = String(self.fixTarget(key, value));
                googletag.pubads().setTargeting(key, value);
                self.pageTargets[key] = value;

            }

        });

        // Allow this function to be chained
        return this;
    }

    fixTarget(key, value) {

        // Fix some of the targets because runtime sends some extraneous text that we don't want
        if (key === 'env') {
            value = value.replace(/&amp;env=/, '');
        }
        if (key === 'leaf') {
            value = value.replace(/&amp;leaf=/, '');
        }
        if (key === 'uri') {
            value = decodeURIComponent(value);
        }

        return value;
    }

    /**
     * Set multiple page-level key/value targets that will be used for all ads,
     * based on a URL-encoded string that contains multiple key=value pairs.
     *
     * <p>This function is provided to more easily support legacy systems
     * that are already outputing a URL-encoded string. It should not be used
     * for new code.</p>
     *
     * @param {String} url A URL-encoded value (sort of). This must contain
     * key=value pairs, separated by '&amp;'. Note it will not work if the
     * key=value pairs are separated by '&'.
     *
     * @example
     * webmd.ads2.setPageTargetUrl('key1=val1&amp;key2=val2');
     */
    setPageTargetUrl(url) {

        let i, key, keyValueArray, keyValuePairs, value;

        // Make sure url is actually passed in as a string
        url = url || '';

        // Split the string on '&amp;'
        keyValuePairs = url.split('&amp;');

        // Loop through the key=value pairs
        for (i = 0; i < keyValuePairs.length; i++) {

            // Split into [key,value]
            keyValueArray = keyValuePairs[i].split('=');
            key = decodeURIComponent(keyValueArray[0] || '');
            value = decodeURIComponent(keyValueArray[1] || '');

            // If we got a key, set the page target
            if (key) {
                this.setPageTarget(key, value);
            }
        }

        // Allow this function to be chained
        return this;
    }

    /**
     * Sets a "category exclusion" for all ads on the page.
     *
     * <p>Note we currently do not support setting a category exclusion just for a single ad.</p>
     *
     * @param String|Array label The name of the category exclusion label, or an array of names.
     *
     * @example
     * webmd.ads2.setPageExclusion('gen2');
     *
     * @example
     * webmd.ads2.setPageExclusion('gen2', 'age11');
     */
    setPageExclusion(label) {

        const self = this;

        // Turn label into an array if it is not already an array
        label = Array.isArray(label) ? label : [label];

        // Wait for google code to be loaded
        self.googleDefer(() => {

            label.forEach((lab) => {
                googletag.pubads().setCategoryExclusion(lab);
                // Save the exclusion label for later reference
                self.pageExclusions[lab] = true;
            });
        });

        // Allow this function to be chained
        return self;
    }

    /**
     * Sets multiple "category exclusions" based on a concatenated string.
     * This is provided for legacy systems that used to pass a value like
     * "_label1_label2_" to the ad server. This function will break apart
     * the string into multiple exclusion labels.
     *
     * @param String labels A string containing one or more labels separated
     * by an underscore character.
     *
     * @example
     * webmd.ads2.setPageExclusionUnderscore('_gen2_m32_h16_o92_age11_age121_age122_');
     */
    setPageExclusionUnderscore(labels) {

        const self = this;

        // Make sure we actually got a string
        labels = labels || '';
        const labels_split = labels.split('_');
        const len = labels_split.length;
        labels.split('_').forEach((label) => {
            if (label) {
                self.setPageExclusion(label);
            }
        })
        // Allow this function to be chained
        return this;
    }

    /**
     * Define an ad slot on the page. Generally you will have already created a div on the page as the ad placeholder,
     * then call this function and pass in the id of that div.    However, you can call this function if necessary before
     * the div has been created.
     *
     * @param Object settings A set of key/value pairs that configures the ad.
     *
     * @param Boolean [settings.collapseAfter] Set this to true if you want the ad to collapse when
     * the ad server returns a blank. If this is not specified then the ad will use
     * the page default behavior (which is to not collapse the ad).
     *
     * @param Boolean [settings.collapseBefore] Set this to true if you want the ad to be
     * collapsed immediately. The ad will expand if the ad server returns an ad.
     * If this is not specified then the ad will use the page default behavior
     * (which is to not collapse the ad).
     *
     * @param String settings.id The id attribute for the ad placeholder div.
     * Each ad must have a unique id. For example, 'ads2-pos-5000'.
     *
     * @param Boolean [settings.immediate=false] Immediately display the ad you defined.
     * This only has an effect if you are defining a new ad after the initial ad call has already been made.
     * It refreshes only the ad that is being defined. If you need to refresh other ads on the
     * page you should not use this.
     *
     * @param Object [settings.keys] Optional set of key/value pairs to set targeting only for
     * this individual ad. Note we typically do not set targeting for individual ads,
     * so use setPageTarget() if you want to set a global target used by all ads.
     *
     * @param String settings.pos The POS value for the ad. Each ad on the page must have a POS
     * value that is unique on the page. For example, '5000'.
     *
     * @param Object [settings.refresh=true] Set this to false if this ad should
     * not be refreshed when calling the webmd.ads2.refresh() function.
     *
     * @param Array [settings.sizes=webmd.ads2.sizes] An array of sizes for the ad.
     * This can be width height values, or multiple width/height pairs.
     * For example, [350,250] or [[300,250],[300,600]].
     * If a value is set within webmd.ads2.sizes for the POS value of this ad, then this parameter
     * is ignored, and the value within webmd.ads2.sizes is used instead.
     * A size must be defined using one of these methods or an error will occur.
     *
     * @example
     * // Ad that supports a single size
     * webmd.ads2.defineAd({id:'ads2-pos-5000', pos:5000, sizes:[300,250]});
     *
     * @example
     * // Ad that supports two different sizes
     * webmd.ads2.defineAd({id:'ads2-pos-5000', pos:5000, sizes:[[300,250], [300,600]]});
     *
     * @example
     * // Create an ad that should not be refreshed
     * webmd.ads2.defineAd({id:'ads2-pos-5000', pos:5000, sizes:[300,250], refresh:false});
     *
     * @example
     * // Set default ad size so the size does not have to be supplied in the defineAd function
     * webmd.ads2.sizes['5000'] = [300,600];
     * // ...
     * webmd.ads2.defineAd({id:'ads2-pos-5000', pos:5000});
     *
     * @example
     * // Override an ad size
     * webmd.ads2.sizes['5000'] = [300,600];
     * // ...
     * webmd.ads2.defineAd({id:'ads2-pos-5000', pos:5000, sizes:[300,250]});
     * // Result: ad will use sizes [300,600] instead of [300,250]
     *
     * @example
     * // Define a new ad after the other ads have already loaded,
     * // and immediately load that ad plus refresh the other ads
     * webmd.ads2.defineAd({id:'my-new-ad', pos:121, sizes:[300,250]}).refresh()
     *
     * @example
     * // Define a new ad after the other ads have already loaded,
     * // and immediately load only that ad
     * webmd.ads2.defineAd({id:'my-new-ad', pos:121, sizes:[300,250]}).refresh({id:'my-new-ad'})
     */
    defineAd(settings) {
        let ignore, sizes, ignoreIds;

        const self = this;

        // Make sure settings is an object
        settings = settings || {};

        // Check if this ad should be ignored based on a global override.
        // Uses a global variable "ads2_ignore", which must be an object
        // where the key is the pos value of the ad that should be ignored,
        // and the value is a boolean (true=ignore the ad).
        // This is used by certain products such as Answers that
        // need to deploy a single template with multiple ads,
        // then show or hide various ads based on back-end logic.
        //
        // Examples:
        //
        // // Ignore ad position 101
        // var ads2_ignore = { 101: true };
        //
        // // Ignore all the ads
        // var ads2_ignore = { all:true };

        ignore = window.ads2_ignore || {};
        ignoreIds = window.ads2_ignoreIds || {};

        if (ignore.all || ignore[settings.pos] || ignoreIds[settings.id]) {
            return;
        }

        // Check if sizes were provided.
        // If webmd.ads2.sizes contains sizes for this POS value, use that to override the size.
        // Otherwise use the sizes that are passed into this function settings.sizes
        settings.sizes = self.sizes[settings.pos] || settings.sizes;

        // Check for required settings
        if (!settings.id) throw ('DefineAd: Missing id!');
        if (!settings.pos) throw ('DefineAd: Missing pos!');
        if (settings.sizes === undefined) {
            // set defaults if provided slot size array is empty
            // webmd.ads2.defaultSizes
            let defaultIndex = Object.keys(webmd.ads2.defaultSizes).indexOf(settings.pos);
            if(defaultIndex > 0) settings.sizes = webmd.ads2.defaultSizes[settings.pos];
        }
        if (!settings.sizes) {
            console.error('DefineAd: Missing sizes on ' + settings.id);
            return;
        }

        // Save these settings because we will use them later to actually display the ads
        self.ads[settings.id] = settings;

        // Add to the google command queue
        self.googleDefer(() => {
            let adUnit, adSlot;

            adUnit = '/' + self.networkCode() + '/' + self.adTarget.join('/');

            const urlParams = new URLSearchParams(window.location.search);
            if (urlParams.get('sandbox') !== null && webmd.ads2.pageTargets.envp === 'qa01') {
                const secd = urlParams.get('secd');
                const pos = urlParams.get('pos');
                if(secd != null && pos != null) {
                    const sandboxPos = pos.split(',');
                    const sandboxSecd = secd.split(',');
                    if(pos.includes(settings.pos)) {
                        adUnit = '/' + '8668145' + '/' + self.adTarget.join('/');
                        const posEcid = sandboxSecd[pos.indexOf(settings.pos)]
                        settings.keys = settings.keys || {};
                        settings.keys['ecd'] = posEcid
                    }
                }
            }

            // Create the ad slot and set the "pos" key
            adSlot = googletag.defineSlot(adUnit, settings.sizes, settings.id);
            if (adSlot === null) {
                // return from the function to avoid js errors. null.addService is undefined.
                console.warn(`adUnit:${adUnit}, id: ${settings.id}, is null. This happens when a slot is defined twice with the same adUnitPath`);
                return;
            }
            
            // Ads can have different services associated with them, so add a service
            adSlot.addService(googletag.pubads());
            // Set the pos key for this individual ad
            adSlot.setTargeting('pos', settings.pos)
            adSlot.setTargeting('ad_slot', settings.id);

            // Save the ad slot for future use when we refresh ads
            settings.slot = adSlot;

            // Set extra individual targeting keys if any were provided
            if (settings.keys) {
                const keysArr = Object.keys(settings.keys);
                keysArr.forEach((key) => {
                    adSlot.setTargeting(key, settings.keys[key]);
                });
            }
            // add the slot level targeting parameters
            if (typeof adSlot !== 'undefined') {

                var spbr = self.frameWork.getASID(settings.pos + "");

                self.frameWork.proclivityData[settings.id] = spbr;
                if (spbr !== null && spbr != 'undefined') {
                    var asid = spbr.a + "";
                    if (asid != "") {
                        adSlot.setTargeting("asid", asid);
                        adSlot.setTargeting("pdp", spbr.pdp);
                        adSlot.setTargeting("pds", spbr.pds);
                        adSlot.setTargeting("pdi", spbr.pdi);
                        adSlot.setTargeting("ppub", (asid != "0") ? "1" : "0");
                    }
                }
            }

            // Override the page-default collapse behavior
            // (only if one of these is set to true)
            if (settings.collapseAfter || settings.collapseBefore) {
                adSlot.setCollapseEmptyDiv(settings.collapseAfter, settings.collapseBefore);
            }

            // Special case: if we define an ad after webmd.ads2.display() has been called,
            // then we need to tell DFP to display the ad immediately.
            // Note this does not make a call to fetch the ad from DFP unless you set
            // the "immediate" flag, it only initializes the ad space.
            // Otherwise you must call refresh() to fetch the ad
            if (self.display.flag) {
                const fn = function () {
                    googletag.display(settings.id);
                };
                fn();

                // Determine if we should immediately fetch this new ad.
                // If we do not immediately fetch the ad, you must later
                // call refresh() to refresh this ad (or refresh all ads).
                if (settings.immediate) {
                    self.refresh({
                        id: settings.id
                    });
                }
            }
        });

        // Allow this function to be chained
        return this;
    }

    slotRenderEnded() {
        this.googleDefer(function () {
            let totCalledSlots = 0;
            googletag.pubads().addEventListener('slotRenderEnded', function (event) {
                if (typeof webmd.ads2.slots !== 'undefined') {
                    totCalledSlots = webmd.ads2.slots.length;
                } else {
                    totCalledSlots = 0;
                    for (let slotK in webmd.ads2.ads) {
                        if (webmd.ads2.ads.hasOwnProperty(slotK)) {
                            ++totCalledSlots;
                        }
                    }
                }
                this.slotCount++;
                if (totCalledSlots === this.slotCount) {
                    this.slotCount = 0;
                    let payLoad = [];
                    webmd.ads2.slots.forEach(x => { //send all rendered ad ids to event payload
                        payLoad.push(x.getSlotId().getDomId());
                    })
                    utils.trigger(document, "dfpRenderComp", payLoad);
                }
            });
        });

        // Allow this function to be chained
        return this;

    }

    addSlotRenderEndedCallback(slotid, callback) {
        this.googleDefer(() => {
            googletag.pubads().addEventListener('slotRenderEnded', (event) => {
                if (event && event.slot && event.slot.getSlotElementId() == slotid) {
                    callback(event);
                } // end if
            }); // end addEventListener()
        }); // end googleDefer()
    }

    /**
     * Disables the initial fetch of ads from Google when the page is first loaded.
     * Calls to refresh() can be used later to display the ads.
     * This must be called before display() is called so it can block the initial load.
     */
    disableInitialLoad() {

        this.googleDefer(function () {
            googletag.pubads().disableInitialLoad();
        });

        // Allow this function to be chained
        return this;
    }

    /**
     * Make the ad call to get all ads, then display the ads.
     * This must be called after all ads have been defined on the page
     * with a call to defineAd() for each one.
     *
     * <p>If you call disableInitialLoad() before calling this function, then the ads will be initialized but will not
     * be loaded and displayed. Then you can call refresh() to later display the ads. This is useful in cases where you
     * need to wait for some other event (for example, to set targeting values) before you load the ads.</p>
     */
    display() {
        const self = this;

        self.googleDefer(function () {

            //display has been called
            self.displayCalled = true;
            //increment the count
            self.displayCalledCount++;

            //trigger DFP_DISPLAY_CALLED only first time the display is called
            if (self.displayCalledCount === 1) {
                utils.trigger(document, "DFP_DISPLAY_CALLED", null);
            }

            //webmd.debug('display');

            // Directs the publisher ads service to make a single request
            // when fetching content for multiple ad slots.
            // This should be called prior to enabling the service.
            googletag.pubads().enableSingleRequest();

            // Enables all Google Publisher Tag services that have been defined for ad slots on the page.
            // This is only needed once per page but including it multiple times will not cause any harm.
            googletag.enableServices();

            // Call display on the ads.
            // Since we are doing enableSingleRequest, the call to display() the first add will make
            // the call to DFP to fetch all the ads. But we must still call display() for each ad on the page.
            function runDisplay() {
                const idArr = Object.keys(self.ads);
                idArr.forEach((id) => {
                    googletag.display(id);

                    /* listen for attribute changes in the returned ad to see if it is blank
                      // for proof of concept, consider for later implementation
                    window.ibAds.addMutationListener({
                        target: document.querySelector(`#${id}`),
                        callBack: window.ibAds.callBacks.hasBlankAd,
                        options: {
                            attributes: true,
                            childList: true,
                            subtree: true,
                            attributeFilter: ['data-load-complete', 'data-google-query-id']
                        }
                    });*/

                    // Set a flag so we know that "display" has already been called.
                    // This is used when we add another ad on the page: if display has already been called
                    // we must call display() on the ad right when we define the ad.
                    self.display.flag = true;
                });
            }
            /*
            if (typeof webmd.tpsvc !== 'undefined' && self.disableRefresh) {
                self.callThirdPartyAdServices(runDisplay);
            } else {
                runDisplay();
            }
            */
            runDisplay();
        });

        // Allow this function to be chained
        return this;
    }

    /**
     * Refresh some or all ads on the page. The ads must have been created with a call to webmd.ads2.defineAd()
     *
     * @param Object [settings] A set of key/value pairs that configure which ads to refresh.
     *
     * @param Object [settings.keys] Optional set of key/value pairs to set page targeting.
     * Refer to setPageTarget() for more information.
     *
     * @param String|Array [settings.id] The id of an ad to refresh,
     * or an object with several ad ids to refresh.
     * If this parameter is used, then only the ads specified here will be refreshed.
     * If the id is in this list, then even if the ad was defined with refresh:false setting,
     * it will force a refresh.
     *
     * @param String|Array [settings.idSkip] The id of an ad to exclude from refresh,
     * or an object with several ad ids to exclude from refresh.
     * This parameter cannot be used if the settings.id parameter is used.
     *
     * @param String|Array [settings.pos] The POS value of an ad to refresh,
     * or an object with several ad positions to refresh.
     * If this parameter is used, then only the ads specified here will be refreshed.
     * If the pos value is in this list, then even if the ad was defined with refresh:false setting,
     * it will force a refresh.
     *
     * @param String|Array [settings.posSkip] The POS value of an ad to exclude from refresh,
     * or an object with several ad positions to exclude from refresh.
     * This parameter cannot be used if the settings.pos parameter is used.
     *
     * @example
     * // Refresh all ads except those that were defined with refresh:false
     * webmd.ads2.refresh()
     *
     * @example
     * // Refresh all ads except those that were defined with refresh:false,
     * // and change the page targeting before refreshing the ads
     * webmd.ads2.refresh({keys:{xpg:"1012"}})
     *
     * @example
     * // Refresh a single ad position
     * webmd.ads2.refresh({pos:5000});
     *
     * @example
     * // Refresh several ad positions
     * webmd.ads2.refresh({pos:{5000:true,5001:true}});
     *
     * @example
     * // Refresh all ads except for one ad position
     * webmd.ads2.refresh({posSkip:5000});
     *
     * @example
     * // Refresh all ads except for several ad positions
     * webmd.ads2.refresh({posSkip:{5000:true,5001:true}});
     *
     * @example
     * // Refresh a specific ad based on it's id
     * webmd.ads2.refresh({id: 'ads-pos-122_1'});
     */
    refresh(settings, correlatorBoo) {
        const self = this;

        // Make sure this is an object in case nothing was passed in
        settings = settings || {};

        function invokeRefreshLogic() {

            //webmd.debug('refresh');

            // Create an array of slots to send to refresh function.
            self.slots = [];

            // Set page targeting if new keys were provided
            if (settings.keys) {
                self.setPageTarget(settings.keys);
            }

            // Loop through all the ads that were defined using webmd.ads2.defineAd()
            // so we can gather an array of ads to refresh
            const idArr = Object.keys(self.ads);
            let adSettings, pos, slot;
            idArr.forEach((id) => {
                adSettings = self.ads[id];
                pos = adSettings.pos;
                slot = adSettings.slot;

                // Check if we should refresh only certain ad positions
                // Note: you cannot use both settings.pos and settings.id
                if (typeof settings.pos !== 'undefined') {

                    // Check of the pos values were provided as an object or a single pos
                    if (self.isPlainObject(settings.pos)) {

                        // It is an object, so see if the pos value of this ad is in the list
                        if (settings.pos[pos] === true) {
                            // Show this ad position
                            self.slots.push(slot);
                        }

                    } else {

                        // settings.pos is a single ad position,
                        // so only refresh that one position
                        if (String(pos) === String(settings.pos)) {
                            self.slots.push(slot);
                        }
                    }

                    // Check if we should refresh only certain ad ids
                    // Note: you cannot use both settings.pos and settings.id
                } else if (typeof settings.id !== 'undefined') {

                    // Check of the pos values were provided as an object or a single pos
                    if (self.isPlainObject(settings.id)) {

                        // It is an object, so see if the pos value of this ad is in the list
                        if (settings.id[id] === true) {
                            // Show this ad position
                            self.slots.push(slot);
                        }

                    } else {

                        // settings.id is a single ad id,
                        // so only refresh that one ad
                        if (String(id) === String(settings.id)) {
                            self.slots.push(slot);
                        }
                    }

                } else {

                    // There was not a list of ads to refresh, so we will
                    // refresh each ad unless it was defined as a non-refreshable ad,
                    // and unless it was listed in the posSkip parameter.

                    // If this ad was defined with refresh:false then it should not be refreshed.
                    // Refer to defineAd() for more info.
                    if (adSettings.refresh === false) {
                        // Move on to the next ad in the list
                        return true;
                    }

                    // If this ad pos is in the settings.posSkip list then it should not be refreshed
                    if (typeof settings.posSkip !== 'undefined') {

                        // Check of the posSkip values were provided as an object or a single pos
                        if (self.isPlainObject(settings.posSkip)) {

                            if (settings.posSkip[pos] === true) {
                                // Skip this ad position
                                return true;
                            }

                        } else {

                            // settings.posSkip is a single ad position so only skip that one position
                            if (String(pos) === String(settings.posSkip)) {
                                // Skip this ad position
                                return true;
                            }
                        }
                    }

                    // If this ad id is in the settings.idSkip list then it should not be refreshed
                    if (typeof settings.idSkip !== 'undefined') {

                        // Check of the idSkip values were provided as an object or a single id
                        if (self.isPlainObject(settings.idSkip)) {

                            if (settings.idSkip[id] === true) {
                                // Skip this ad
                                return true;
                            }
                        } else {

                            // settings.idSkip is a single ad id so only skip that one ad
                            if (String(id) === String(settings.idSkip)) {
                                // Skip this ad
                                return true;
                            }
                        }
                    }

                    // If we get here, then the ad should not be skipped,
                    // so add it to the list of ads to be refreshed
                    self.slots.push(slot);
                }
            });

            // PPE-56979: Blank ads on Slideshows, monographs, & quizzes
            // from Google DoubleClick Publisher:
            // Our best bet is to use the GPT clear() function googletag.pubads().clear() in the ad tags before the refresh() function.
            // This will clear the object values of the ad slot where the creative is intended to render before auto-refreshing the slot.
            if (self.slots.length > 0) {
                googletag.pubads().clear(self.slots);
            } else {
                googletag.pubads().clear();
            }

            if (correlatorBoo == null) {
                googletag.pubads().refresh(self.slots);
            } else {
                googletag.pubads().refresh(self.slots, {
                    changeCorrelator: correlatorBoo
                });
            }
            //webmd.debug('refresh - inside timeout');

        }

        //if display was called before this,
        //fire invokeRefreshLogic
        if (self.displayCalled) {
            //webmd.debug('invoking refresh not using event');
            if (typeof webmd.tpsvc !== 'undefined') {
                self.callThirdPartyAdServices(invokeRefreshLogic);
            } else {
                invokeRefreshLogic();
            }
        }

        //otherwise, wait for DFP_DISPLAY_CALLED and then
        //fire invokeRefreshLogic
        else {
            document.addEventListener("DFP_DISPLAY_CALLED", function () {
                const fn = function () {
                    //using setTimeout to skip curerent frame before invoking
                    setTimeout(invokeRefreshLogic, 1);
                };

                if (typeof webmd.tpsvc !== 'undefined') {
                    self.callThirdPartyAdServices(fn);
                } else {
                    fn();
                }
            });
        }
        // Allow this function to be chained
        return this;
    }

    /*
     *   !  from consumer implementation: https://stash.portal.webmd.com/projects/CONSUI/repos/scripts/browse/src/common/webmd.ads2.js#653,753,782,794,823
     * Proxy method to all 3rd party ad services.
     * All 3rd party Ad service handlers are stored in the thirdPartyAdServices global variable.
     * This method executes each of those handlers and delays the ad request for a specified length of time
     * so that 3rd party ad services get a chance to complete processing.
     * if all services finish processing before the timeout elapses, the ad request is sent.
     * otherwise, the ad request will be sent once the timeout expires.
     *
     * This all happens asynchronously to allow us to work with multiple 3rd party services
     * while keeping the timeout delay near constant.
     *
     * @param Object adRequest - Ad request handler.
     * @return {void}
     */
    callThirdPartyAdServices(adRequest) {
        let cmdsLength = webmd.tpsvc.cmds.length;
        let promisesLength = webmd.tpsvc.promises.length;
        let promises = webmd.tpsvc.promises;
            promises = promises.map(e => e())
         // if there are no 3rd party ad services to be executed, make the ad request and exit
         if (cmdsLength < 1 && promisesLength < 1){
             adRequest();
             return;
         }
         //new promise logic to wait ad service
         // Set a timeout for 500ms
         const timeout = setTimeout(() => {
             console.log('Promises not resolved within 500ms.');
             // Execute regular flow of code here
             adRequest();
             
         }, 600);
         var priceObj = [];
         // Wait for both Promises to settle and clear the timeout
         Promise.allSettled(promises)
         .then(results => {
            results.forEach((result, index) => {
              if (result.status === "fulfilled") {
                console.log(`Promise ${index} was fulfilled with value ${result.value}`);
                priceObj[index]=result.value;
            } else {
                console.log(`Promise ${index} was rejected with reason ${result.reason}`);
              }
            });

            clearTimeout(timeout);
            adRequest();
            // Handle the results of the settled Promises here
            console.log('Promises settled:', results);
        });
    }
    // Set publisher-provided ID for Google Ad Manager
    setPublisherProvidedId(ppid){
        const self = this;
        self.googleDefer(function() {
            googletag.pubads().setPublisherProvidedId(ppid);
        });
    }

}
